<style scoped>
.removeButtomMargin {
  margin-bottom: 0px !important;
}
.icon {
  color: #477B7E !important;
}
.moreDense {
  padding-top: 4px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
}

.removeBorder {
  border-width: 0px !important;
}
.coloredText {
  color: inherit;
}

#additionalInformation {
  color: black;
}
</style>

<template>
  <!-- <v-fade-transition> -->
  <v-alert
    class="removeButtomMargin"
    outlined
    text
    v-bind="$attrs"
    icon="mdi-forum"
    :ref="'alert'"
  >
    <div style="color: black;">
      <!-- Vorschau Text mit ... -->
      <span
        v-if="!expandableShowText && expandableLocal"
        v-html="textHtml.slice(0, expandableCharacterCount) + '...'"
      ></span>

      <!-- Kompletter Text -->
      <!-- <span
        v-if="expandableShowText || !expandableLocal"
        v-html="textHtml"
      ></span> -->

      <div v-if="expandableShowText || !expandableLocal">
        <span
          v-if="textHtml !== ''"
          v-html="textHtml"
        ></span>
        <span v-else-if="textHtmlArray.length < 2" v-html="textHtmlArray[0]"></span>
        <ul v-else>
          <li v-for="index in textHtmlArray.length" :key="index">
            {{ textHtmlArray[index - 1] }}
          </li>
        </ul>
      </div>

      <a v-if="expandableLocal" @click="switchShowText()">{{ readMoreText }}</a>

      <!-- Zusätzliche Infos -->
      <span
        v-if="
          additionalInformationHtml &&
          !additionalInformationHtml.includes('undefined') && //BUG! If we have success messages, sometimes additionalInformationHtml is 'undefined ' don't know why ...
            ((expandableLocal && expandableShowText) || !expandableLocal)
        "
      >
        <v-divider class="my-1 info" style="opacity: 0.22"></v-divider>
        <!-- Zusätzliche Infos
        <v-icon class="icon">{{ "mdi-information" }}</v-icon> -->
        <v-alert dense type="info" outlined class="moreDense removeBorder">
          <a class="coloredText" @click="switchShowAdditionalInformation()"
            >Zusätzliche Infos
            <!-- <v-icon class="icon">{{ "mdi-information" }}</v-icon> -->
          </a>

          <v-btn icon small="" @click="switchShowAdditionalInformation()">
            <v-icon class="icon">{{
              expandableShowAdditionalInformation
                ? "mdi-chevron-up"
                : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
          <div
            id="additionalInformation"
            v-if="expandableShowAdditionalInformation"
            outlined
            text
            type="info"
            v-html="additionalInformationHtml"
          ></div>
        </v-alert>
      </span>
    </div>
  </v-alert>
  <!-- </v-fade-transition> -->
</template>

<script>
import AppHelper from "@/common/AppHelper";

export default {
  props: {
    textHtml: { type: String, required: true },
    textHtmlArray: { type: Array, default: () => []},
    additionalInformationHtml: { type: String, required: false }, // additional information
    expandable: { type: Boolean, default: null },
    expandableInitialOpen: { type: Boolean, default: false },
    expandableCharacterCount: { type: Number, default: 450 },
    /* the id of the component this element is embedded into. needed for matomo  */
    parentId: { type: String, required: true },
  },
  data: function() {
    return {
      expandableShowText: this.expandableInitialOpen,
      expandableShowAdditionalInformation: false,
      expandableLocal:
        this.expandable != null
          ? this.expandable
          : this.textHtml.length > this.expandableCharacterCount, // we can make the AppAlertTooltip expandable by prop
      readMoreText: "weiterlesen",
    };
  },
  methods: {
    switchShowText: function() {
      this.expandableShowText = !this.expandableShowText;
      this.readMoreText = "weniger";
      AppHelper.trackMatomoEvent(
        this,
        "AppAlertTooltip",
        "id:10; Text aufgeklappt;",
        this.parentId
      );

      if (!this.expandableShowText) {
        this.expandableShowAdditionalInformation = false;
        this.readMoreText = "weiterlesen";
        this.scrollToAlert();
        AppHelper.trackMatomoEvent(
          this,
          "AppAlertTooltip",
          "id:11; Text zugeklappt;",
          this.parentId
        );
      }
    },
    switchShowAdditionalInformation: function() {
      this.expandableShowAdditionalInformation = !this
        .expandableShowAdditionalInformation;
      if (this.expandableShowAdditionalInformation) {
        AppHelper.trackMatomoEvent(
          this,
          "AppAlertTooltip",
          "id:12; Zusätzliche Infos aufgeklappt;",
          this.parentId
        );
      } else {
        AppHelper.trackMatomoEvent(
          this,
          "AppAlertTooltip",
          "id:13; Zusätzliche Infos zugeklappt;",
          this.parentId
        );
      }
    },

    scrollToAlert: function() {
      this.$scrollTo(this.$refs["alert"].$el);
    },
  },
};
</script>
