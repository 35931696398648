<!-- highly inspired from https://github.com/TahaSh/vue-expandable-image/ -->
<style scoped>
.expandable-image {
  position: relative;
  transition: 0.25s opacity;
  cursor: zoom-in;
  width: inherit;
  height: inherit;
}
body > .expandable-image.expanded {
  z-index: 9;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  opacity: 0.2;
  padding-bottom: 0 !important;
  cursor: default;
}
body > .expandable-image.expanded > .image-container {
  /* width: 40%; */
  /* width: 60%; */
  opacity: 1 !important;
  /* max-width: 1200px; */
  /* max-height: 100%; */
  object-fit: contain;
  margin: 0 auto;
  z-index: 9;
  width: 80%;
  height: 80%;
}

body > .expandable-image.expanded > .image-container > .v-image {
  width: 100%;
  height: 100%;
  /* background-color: white; */
}
body > .expandable-image.expanded > .image-container > .close-button {
  display: block;
}
.close-button {
  position: relative;
  top: 10%;
  right: -94%;
  display: none;
  cursor: pointer;
  z-index: 9;
}
.expand-button svg,
.close-button svg {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}
.expand-button svg path,
.close-button svg path {
  fill: #fff;
}
.expand-button {
  position: absolute;
  z-index: 9;
  right: 0px;
  top: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  opacity: 0.8;
}
.expand-button svg {
  width: 10px;
  height: 10px;
}
.expand-button path {
  fill: #000;
}
/* .expandable-image .v-img {
  width: 100%;
  height: 100%;
  z-index: 9;
} */
body > .expandable-image.expanded > .filling-background {
  display: block;
}
.filling-background {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
}
.image-container {
  width: inherit;
  height: inherit;
}
.image-container > .v-image {
  width: inherit;
  height: inherit;
}
</style>
<template>
  <div
    class="expandable-image"
    :class="{
      expanded: expanded,
    }"
    @click.stop="expanded = true"
  >
    <i v-if="!expanded" class="expand-button">
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path
          fill="#000000"
          d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z"
        />
      </svg>
    </i>

    <div class="filling-background"></div>
    <div class="image-container">
      <i v-if="expanded" class="close-button">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path
            fill="#666666"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
          />
        </svg>
      </i>
      <v-img contain v-bind="$attrs" />
    </div>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";

export default {
  name: "AppExpandableImage",
  props: {
    parentId: { String, required: true },
    elementId: { String, required: true },
  },
  data() {
    return {
      expanded: false,
      closeButtonRef: null,
    };
  },
  methods: {
    closeImage(event) {
      this.expanded = false;
      event.stopPropagation();

      let action = event.type;
      if (event.type == "click") {
        action = event.type + " auf: " + event.currentTarget.className;
      }

      AppHelper.trackMatomoEvent(
        this,
        "AppExpandableImage",
        "id:15; Element: " +
          this.elementId +
          " verkleinert; Aktion: " +
          action +
          ";",
        this.parentId
      );
    },
  },
  watch: {
    expanded(status) {
      this.$nextTick(() => {
        if (status) {
          this.cloned = this.$el.cloneNode(true);
          this.closeButtonRef = this.cloned.querySelector(".close-button");
          this.closeButtonRef.addEventListener("click", this.closeImage);

          // document.body.style.overflow = "hidden";
          this.cloned.addEventListener("touchmove", this.closeImage);
          this.cloned.addEventListener("click", this.closeImage);
          window.addEventListener("scroll", this.closeImage);
          document.body.appendChild(this.cloned);
          setTimeout(() => {
            this.cloned.style.opacity = 1;
          }, 0);

          AppHelper.trackMatomoEvent(
            this,
            "AppExpandableImage",
            "id:14; Element: " + this.elementId + " vergrößert;",
            this.parentId
          );
        } else {
          this.cloned.style.opacity = 0;
          this.cloned.removeEventListener("touchmove", this.closeImage);
          window.removeEventListener("scroll", this.closeImage);
          this.cloned.removeEventListener("click", this.closeImage);
          setTimeout(() => {
            this.closeButtonRef.removeEventListener("click", this.closeImage);
            this.cloned.remove();
            this.cloned = null;
            this.closeButtonRef = null;
            // document.body.style.overflow = "auto";
          }, 250);
        }
      });
    },
  },
};
</script>
