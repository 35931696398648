<template>
  <!-- @click="onClick" -->
  <!--
  <v-btn v-bind="$attrs" v-on="$listeners" icon>
    <v-icon>mdi-information</v-icon>
    <slot></slot>
  </v-btn>
  -->
  <v-dialog v-model="dialog" width="70%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon x-large color="primary">
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        <slot>
          <p style="color: red">
            Hier sollte eine Erklärung / Information stehen.
          </p>
        </slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Schließen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHelper from "@/common/AppHelper";

export default {
  name: "AppButtonInfo",

  props: {
    title: { type: String, default: "Information" },
    elementId: String,
  },

  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    dialog: function () {
      AppHelper.trackMatomoEvent(
        this,
        "AppDialogInfo",
        "id:74; Status offen: " +
        this.dialog +
        "; elementId: " +
        this.elementId +
        ";",
        "AppDialogInfo"
      );
    },
  },
  methods: {
    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>